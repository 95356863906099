import * as React from 'react';

export const useMobileView = (size = 520) => {
	const [isMobile, setIsMobile] = React.useState(false);

	React.useEffect(() => {
		const resize = () => {
			setIsMobile(window.innerWidth < size);
		};

		resize();
		window.addEventListener('resize', resize);
		return () => window.removeEventListener('resize', resize);
	}, []);

	return isMobile;
};
