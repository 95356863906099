import * as React from 'react';

type formEventHandler = (event: React.FormEvent<HTMLInputElement>) => void;

interface PhoneInputProps {
	className: string;
	id: string;
	value: string;
	format?: string;
	onChange: formEventHandler;
}

interface PhoneInputState {
	value: string;
}

/**
	Phone format: +d (ddd) ddd-dddd
    d is used for digit, other symbols are left as is
 */
export default class PhoneInput extends React.Component<PhoneInputProps> {
	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event: React.FormEvent<HTMLInputElement>) {
		let value = event.currentTarget.value.replace(/[^0-9]/g, '');
		const format = this.props.format || '+d (ddd) ddd-dddd';

		if (value.length > format.replace(/[^d]/g, '').length) return;

		for (let i = 0; i < value.length && i < format.length; i++) {
			if (value[i] !== format[i] && format[i] !== 'd') {
				value = value.slice(0, i) + format[i] + value.slice(i);
			}
		}

		event.currentTarget.value = value;
		this.props.onChange(event);
	}

	render() {
		return (
			<input
				className={this.props.className}
				id={this.props.id}
				value={this.props.value}
				onChange={this.handleChange}
			/>
		);
	}
}
