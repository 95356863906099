import React from 'react';
import { useHistory } from 'react-router-dom';

import {
	FieldProps, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import * as Yup from 'yup';

import FormikField from '@common/react/components/Forms/FormikField/FormikField';
import { emailValidator, phoneValidator, simpleStringValidator } from '@common/react/utils/validationHelpers';
import { request } from '@common/react/components/Api';
import PhoneInput from '@common/react/components/Forms/PhoneInput/PhoneInput';

import LogoLoader from '@commonTuna/react/components/UI/LogoLoader/LogoLoader';
import { useMobileView } from '@commonTuna/react/utils/useMobileView';
import { Device } from '@commonTuna/react/objects/Device';

interface FormValues {
	name: string;
	email: string;
	text: string;
	phone: string;
	path: string;
	device: Device;
}

interface Props {
	className?: string;
	idPrefix?: string;
	hideTitle?: boolean;
	title?: string;
}

const initialValues: FormValues = {
	name: '',
	email: '',
	text: '',
	phone: '',
	path: '',
	device: Device.Desktop,
};

const validationSchema = Yup.object().shape({
	name: simpleStringValidator,
	phone: phoneValidator,
	email: emailValidator,
	// text: simpleStringValidator
});

let timer: any;
const successMessage = 'Thank you! Your inquiry has been successfully sent. Our manager will contact you as soon as possible';

const NewConcatForm: React.FC<Props> = ({
	className = '', idPrefix = '', hideTitle, title = 'Book Appointment',
}) => {
	const [message, setMessage] = React.useState('');
	const [error, setError] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [visible, setVisible] = React.useState(true);

	const isMobile = useMobileView();
	const history = useHistory();

	React.useEffect(() => {
		if (!loading) {
			setTimeout(() => setVisible(true), error ? 1000 : 5000);
		}
	}, [loading, error]);

	const handleSubmit = (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
		setError('');
		setMessage('');
		setLoading(true);
		setVisible(false);

		request<FormValues, any, any>('inquiry', {
			name: values.name,
			email: values.email,
			text: values.text,
			phone: values.phone,
			mobile: isMobile,
			path: history.location.pathname,
			device: isMobile ? Device.Mobile : Device.Desktop,
		})
			.then((res) => {
				setTimeout(() => {
					setMessage(successMessage);
					formikHelpers.resetForm({ values: initialValues });
				}, 800);
				setTimeout(() => setMessage(''), 15000);
			})
			.catch((e) => {
				setError('Something went wrong');
				setTimeout(() => setLoading(false), 300);
			})
			.finally(() => {
				if (timer) {
					clearTimeout(timer);
				}
				timer = setTimeout(() => setLoading(false), 1000);
			});
	};

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		form: FormikProps<FormValues>,
		name,
	) => {
		const { value } = e.currentTarget;
		form.setFieldValue(name, value);
	};

	return (
		<div className={`inquiry-wrapper clearfix ${!visible ? 'form-sending' : ''} ${className}`}>
			{(loading || error) && <div className="loader">
				{loading && <LogoLoader />}
				{!loading && error && <div className="error">
					<i className="fa fa-exclamation" />
					{' '}
					{error}
				</div>}
			</div>}
			{message
				&& <div className="success-container">
					<div className="icon">
						<i className="fa fa-check" />
					</div>
					<div className="success">{message}</div>
				</div>
			}
			<div className="inquiry">
				{title && <h2 className={`col-sm-12 ${hideTitle ? 'hide-mobile' : ''}`}>{title}</h2>}
				<Formik
					initialValues={initialValues}
					onSubmit={handleSubmit}
					validationSchema={validationSchema}
				>
					{(formikBag: FormikProps<FormValues>) => {
						return (<Form>
							<FormikField
								title="NAME"
								fieldName="name"
								inputId={`${idPrefix}name`}
								containerClassName="col-sm-12"
								inputProps={{
									required: true,
									id: `${idPrefix}name`,
								}}
							/>
							<FormikField
								title="EMAIL"
								fieldName="email"
								inputId={`${idPrefix}email`}
								containerClassName="col-sm-12"
								inputProps={{
									required: true,
									id: `${idPrefix}email`,
								}}
							/>
							<FormikField
								title="PHONE"
								fieldName="phone"
								inputId={`${idPrefix}phone`}
								containerClassName="col-sm-12"
								render={({ field, form }: FieldProps<string, FormValues>) => (<>
									<PhoneInput
										id={`${idPrefix}phone`}
										value={field.value}
										className="form-control"
										onChange={(e: any) => handleChange(e, form, field.name)}
									/>
								</>
								)}
							/>
							<FormikField
								title="MESSAGE"
								fieldName="text"
								inputId={`${idPrefix}text`}
								containerClassName="col-sm-12"
								inputProps={{
									id: `${idPrefix}text`,
								}}
								render={({ field }: FieldProps) =>
									<textarea
										className="form-control"
										{...field}
										id={`${idPrefix}text`}
										value={field.value === null ? '' : field.value}
									/>}
							/>
							<div className="col-sm-12">
								<button
									type="submit"
									className="btn submit-button"
									disabled={loading}
								>
									SUBMIT
								</button>
							</div>
						</Form>
						);
					}}
				</Formik>
				<div className="col-sm-12 messages">
					{error && <div className="error">
						<i className="fa fa-exclamation" />
						{' '}
						{error}
					</div>}
				</div>
			</div>
		</div>
	);
};

export default NewConcatForm;
